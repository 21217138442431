import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Modal,
  Dialog,
  DialogContent,
  FormControlLabel,
  CircularProgress,
  Card,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import ReactPlayer from "react-player/lazy";
import clsx from "clsx";
// import IntroVideo from "../../assets/video/OmidIntro.mp4";
import { useTheme } from "@material-ui/core/styles";
import { RELATE_APP_BASE_URL, LIVE_URL } from "../../utils/constants";
import { Link } from "gatsby";
import { useMediaQuery } from "@material-ui/core";
import CourseCard from "../common/courseEpisodeCard";
const IntroVideo =
  process.env.REACT_APP_INTRO_VIDEO ||
  "https://ipfsn.omidlife.com/ipfs/Qmf8khwqMWZ1DCT4AMQinJ4TYQcrRXUD1zSFZKUCEGKKCA";
function ViewCourseDesktop(props) {
  const { courseDetail } = props;
  const { instructorDetail } = props;

  const classes = useStyles();
  const [previewDialog, setPreviewDialog] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [image, setImage] = useState();
  const [imagePath, setImagePath] = useState("");
  const [courseStatus, setCourseStatus] = useState(false);
  const [introVideo, setIntroVideo] = useState(true);
  const [subscriptionExpire, setSubscriptionExpire] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [configs, setConfigs] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(true);

  const handleClosePreviewDialog = () => {
    setPreviewDialog(false);

    setThumbnail(null);
    setIntroVideo(true);
  };

  useEffect(() => {
    if (instructorDetail?.id) {
      setCourseStatus(instructorDetail?.status === "Active" ? true : false);
    }
  }, [instructorDetail]);

  const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex && hex.slice(1, 3), 16),
      g = parseInt(hex && hex.slice(3, 5), 16),
      b = parseInt(hex && hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };
  const handleOpenPreviewDialog = () => {
    setPreviewDialog(true);
  };
  const handleEnded = () => {
    if (introVideo) {
      setIntroVideo(!introVideo);
    }
  };

  /*   function preloadImage(src) {
    var ext = src.split(".");
    var text = ext[2] + "_thumb";
    ext.splice(2, 1, text);
    let newUrl = ext.join(".");
    console.log(ext, "******");
    console.log(newUrl, "*newUrl*****");
    // let result = src.replace("png", "webp");
    //   setImage(newUrl);
    setImage(src);
  }
 */
  /*   if (imagePath === "") {
    let src = instructorDetail?.coverUrl;
    if (!src.includes(".webp")) {
      var ext = src.split(".");

      var text = ext[0] + "_thumb";

      let NewUrl = text + ".webp";

    setImagePath(src);
  } */

  // useEffect(() => {
  //   preloadImage(`${RELATE_APP_BASE_URL}${instructorDetail?.coverUrl}`);
  // }, []);

  const courseViewHeroImageCard = (
    <div
      className={classes.root}
      style={{
        backgroundImage: `url(${RELATE_APP_BASE_URL}${instructorDetail?.coverUrl}),linear-gradient(360deg, rgba(21, 57, 89, 0.8) 0%, rgba(123, 146, 166, 0.8) 100%)`,
        backgroundPosition: "center center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        height: 0,
        paddingTop: "56.25%",
        position: "relative",
      }}
    >
      <div
        className={classes.root}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <Grid container style={{ height: "100%" }}>
          <Grid container justify="flex-end" spacing={1}>
            <Grid item xs={2} style={{ height: "25px" }}></Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
  const [selectedCategory, setSelectedCategory] = useState("");
  const [courses, setCourses] = useState([]);
  useEffect(() => {
    if (instructorDetail?.courses?.length > 0) {
      setCourses(instructorDetail?.courses);
    }
  }, [instructorDetail?.courses]);

  const handleSelectCategory = (e) => {
    if (instructorDetail?.courses?.length > 0) {
      const filterCourses = instructorDetail?.courses?.filter(
        (item) =>
          e.target.value === item?.categoryId?.toString() ||
          e.target.value === ""
      );
      setCourses([...filterCourses]);
    }
    setSelectedCategory(e.target.value);
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          container
          style={{
            padding: isMobile ? "10px 10px 0px 10px" : "100px 20px 0px 20px",
          }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h1"
              className={classes.addFriendTxt}
              style={{
                color: "#3C4A6B",
                fontSize: "18px",
                marginBottom: "5px",
                display: "block",
              }}
            >
              {instructorDetail?.name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            xl={7}
            lg={7}
            container
            className={isMobile ? "" : classes.scroll}
            style={{ padding: isMobile ? "0px" : "0px 20px 0px 0px" }}
          >
            <Dialog
              // fullScreen={fullScreen}
              fullWidth={true}
              minWidth={"sm"}
              // maxWidth={'lg'}
              open={previewDialog}
              onClose={handleClosePreviewDialog}
              aria-labelledby="responsive-dialog-title"
              classes={{ root: classes.dialogRoot }}
            >
              <DialogContent style={{ background: "#fff" }}>
                {thumbnail ? (
                  <Card
                    elevation={0}
                    className={classes.ratio}
                    style={{ borderRadius: "0px", background: "#fff" }}
                  >
                    <img
                      src={`${RELATE_APP_BASE_URL}${instructorDetail?.coverUrl}`}
                      className={classes.imageCardRoot}
                      style={{ borderRadius: "0px" }}
                      alt=""
                      loading="lazy"
                    />
                  </Card>
                ) : (
                  <div
                    className={classes.playerWrapper}
                    style={{ background: "#fff" }}
                  >
                    <div
                      className={clsx(classes.reactPlayer, classes.preLoadBody)}
                    >
                      {loadingProgress && (
                        <CircularProgress style={{ color: "#09A9BB" }} />
                      )}
                    </div>
                    <ReactPlayer
                      controls="true"
                      height="100%"
                      width="100%"
                      className={classes.reactPlayer}
                      // onProgress={handleProgress}
                      onEnded={handleEnded}
                      onStart={() => setLoadingProgress(false)}
                      playing={true}
                      // light={sessionThumbnail}
                      onError={() => {
                        previewDialog &&
                          setThumbnail(
                            `${RELATE_APP_BASE_URL}${instructorDetail?.coverUrl}`
                          );
                      }}
                      url={
                        introVideo ? IntroVideo : instructorDetail?.previewUrl
                      }
                      config={{
                        youtube: {
                          playerVars: { showinfo: 1 },
                        },
                        file: {
                          attributes: {
                            preload: "none",
                          },
                        },
                      }}
                    />
                  </div>
                )}
              </DialogContent>
            </Dialog>
            <Grid item xs={12}>
              {courseViewHeroImageCard}
            </Grid>
            {/* <Grid item xs={12} style={{ marginTop: "15px" }}>
                {instructorDetail?.previewUrl && (
                <Button
                  variant="contained"
                  color="default"
                  style={{ backgroundColor: "#09B1D3" }}
                  className={classes.button1}
                  // startIcon={<PlayArrow />}
                  onClick={handleOpenPreviewDialog}
                >
                  Preview
                </Button>
              )}

                <Link
                target="_blank"
                style={{ textDecoration: "none" }}
                // to={"subscription-plans"}
                to={
                  courseDetail?.plan?.name === "Subscription"
                    ? `/subscription-plans`
                    : `${RELATE_APP_BASE_URL}/view-course/${courseDetail?.slug}?tab=signup`
                }
              >
                <Button
                  className={classes.button1}
                  style={{ backgroundColor: "#F3B527" }}
                >
                  {courseDetail?.plan?.name === "Paid"
                    ? `Join for ( ${courseDetail?.price} )`
                    : courseDetail?.plan?.name === "Subscription"
                    ? "Subscribe"
                    : "Join for free"}
                </Button>
              </Link>
            </Grid>
 */}
            <Grid
              container
              direction="row"
              style={{ margin: "0px 0px 0px 0px" }}
            >
              {/* <Grid item xs={12}>
                <Typography
                  className={classes.addFriendTxt}
                  style={{
                    color: "#3C4A6B",
                    fontSize: "18px",
                    marginBottom: "5px",
                    display: "block",
                  }}
                >
                  {instructorDetail?.name}
                </Typography>
              </Grid> */}
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  className={classes.addFriendTxt}
                  style={{
                    color: "#3C4A6B",
                    fontSize: "18px",
                    marginBottom: "5px",
                    display: "block",
                  }}
                >
                  Title
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3" className={classes.relationSubTxt}>
                  {instructorDetail?.title}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              style={{ margin: "0px 0px 0px 0px" }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  className={classes.addFriendTxt}
                  style={{
                    color: "#3C4A6B",
                    fontSize: "18px",
                    marginBottom: "5px",
                    display: "block",
                  }}
                >
                  Description
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  className={classes.relationSubTxt}
                  dangerouslySetInnerHTML={{
                    __html: unescape(instructorDetail?.description),
                  }}
                ></Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={5}
            xl={5}
            lg={5}
            container
            justifyContent="center"
            style={{
              padding: "0px 15px 0px 15px",
              background: "#E8EEEF",
              // marginTop: "25px",
            }}
            className={isMobile ? "" : classes.scroll}
          >
            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>
              <Typography className={classes.heading}>Courses</Typography>
            </Grid>

            <Grid item xs={8} sm={8} md={6} lg={6} xl={6}>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={selectedCategory}
                onChange={handleSelectCategory}
                style={{ paddingTop: isMobile ? "12px" : "20px" }}
              >
                <Grid container className={classes.hideScrollBar}>
                  <Grid item>
                    <FormControlLabel
                      style={{
                        width: "max-content",
                        color: selectedCategory === "" ? "#09B1D3" : "#3D3D3D",
                      }}
                      value=""
                      control={<Radio style={{ color: "#09B1D3" }} />}
                      label="All"
                    />
                  </Grid>

                  {props?.categories

                    ?.filter((item) => item?.name !== "All")
                    ?.map((category) => {
                      return (
                        <Grid item>
                          <FormControlLabel
                            style={{
                              width: "max-content",
                              color:
                                selectedCategory === category?.id?.toString()
                                  ? "#09B1D3"
                                  : "#3D3D3D",
                            }}
                            value={(category?.id).toString()}
                            control={<Radio style={{ color: "#09B1D3" }} />}
                            label={category?.name}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              {courses?.length > 0 ? (
                courses?.map((item, ind) => {
                  return (
                    <Grid item xs={12} key={item.id}>
                      <Link
                        to={`/courses/${item.slug}/`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <CourseCard
                          data={{
                            image: item?.image,
                            name: item?.name,
                            description: item?.remarks,
                          }}
                          ind={ind + 1}
                        />
                      </Link>
                    </Grid>
                  );
                })
              ) : (
                <Grid item xs={12}>
                  <Typography variant="h2" className={classes.notFoundText}>
                    NO ACTIVE COURSE AT THE MOMENT
                  </Typography>
                </Grid>
              )}
            </Grid>
            {/*  <Grid
              item
              xs={12}
              // style={{ marginTop: "87px" }}
              style={{ marginTop: "10px" }}
              justifyContent="center"
            >
                <CourseInstructorIntroCard
                instructor={courseDetail?.user}
                tags={courseDetail?.tags}
                category={courseDetail?.category}
                remarks={courseDetail?.remarks}
                status={courseDetail?.status}
                courseDetail={courseDetail}
              />
            </Grid> */}

            {/* <Grid
              item
              xs={12}
              style={{ marginTop: 15 }}
              justifyContent="center"
            >
               <CoursePlanCard courseDetail={courseDetail} />
            </Grid> */}

            {/*  <Grid item xs={12}>
              <Typography
                style={{
                  fontFamily: "Reef",
                  fontStyle: "normal",
                  fontWeight: 900,
                  fontSize: "22px",
                  paddingTop: "20px",
                  color: "#1B1B1B",
                  textAlign: "center",
                }}
              >
                 {intl.formatMessage({ id: "reviews" })}
              </Typography>
            </Grid> */}

            {/* <Grid item xs={12}>
              <CourseReviews courseReviews={courseDetail?.reviews_ratings} />
            </Grid>

            <Grid item xs={12}>
              {(courseDetail?.isUserTaking ||
                courseDetail?.user?.id === authReducerState?.user?.id) && (
                <PostReviewCard courseId={courseDetail?.id} />
              )}
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      {/* <CustomerCardList
        open={openPaymentDialog}
        setOpen={setOpenPaymentDialog}
        setRecommendUpgrade={setRecommendUpgrade}
        setShareOpen={setShareOpen}
        courseId={courseDetail?.id}
        plan={courseDetail?.plan}
        price={getPrice(
          courseDetail?.price,
          reducerUser?.purchasedSubscription,
          "course"
        )}
      /> */}
    </>
  );
}

export default ViewCourseDesktop;

const useStyles = makeStyles((theme) => ({
  hideScrollBar: {
    flexWrap: "unset",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  button1: {
    textTransform: "none",
    color: "#fff",
    margin: "0px 10px 10px 0px",
    //    height: '52px'
  },
  notFoundText: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    fontFamily: "Reef",
  },
  TabAppBar: {
    backgroundColor: "#FFF",
    width: "100%",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
  },
  addFriendTxt: {
    fontFamily: "Roboto",
    fontFtyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#09A9BB",
  },
  tabLabel: {
    color: "#666",
    // fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    minWidth: "20%",
    textTransform: "none",
    textAlign: "left",
  },
  relationSubTxt: {
    fontFamily: "Reef",
    fontStyle: "normal",
    fontWeight: 900,
    // color: "#B0B0B0",
    fontSize: "16px",
    color: "black",
  },
  tabSelected: {
    color: "#47b8c4",
    // fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
  },
  root: {
    width: "100%",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  media: {
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(360deg, rgba(21, 57, 89, 0.8) 0%, rgba(123, 146, 166, 0.8) 100%)",
    borderRadius: "11px",
    transform: "rotate(360deg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  courseTitle: {
    // fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    color: "#000",
    paddingBottom: "15px",
  },
  courseDescription: {
    // fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "27px",
    letterSpacing: "0.015em",
    color: "#FFFFFF",
    padding: "20px 0px",
  },
  proceedButton: {
    background: "#fbae02",
    color: "#fff",
    "&:hover": {
      background: "#fbae02",
      color: "#fff",
    },
    "&:focus": {
      background: "#fbae02",
      color: "#fff",
    },
  },
  subscribeButton: {
    background: "#088A2D",
    color: "#fff",
    "&:hover": {
      background: "#088A2D",
      color: "#fff",
    },
    "&:focus": {
      background: "#088A2D",
      color: "#fff",
    },
  },
  heading: {
    fontFamily: "Reef",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "27px",
    color: "#000000",
    marginBottom: "3px",
    marginTop: "20px",
  },

  startNowBtn: {
    height: "2.463em",
    width: "95%",
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    // fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },
  subscribeBtn: {
    height: "2.463em",
    width: "95%",
    background: "linear-gradient(360deg, #09A9BB 0%, #A6D6FF 100%)",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    // fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },
  CourseImageCardText: {
    // fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "10px",
    color: " #FFFFFF",
  },
  instructorIntroText: {
    // fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
  },
  instructorIntroTextTitle: {
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1B1B1B",
  },
  instructorIntroTextSubTitle: {
    fontSize: "16px",
    lineHeight: "22px",
    color: "#B0B0B0",
    letterSpacing: "0.015em",
    padding: "10px 0",
  },
  courseTags: {
    background:
      "linear-gradient(180deg, rgba(123, 146, 166, 0.95) 0%, rgba(21, 57, 89, 0.95) 100%)",
    borderRadius: "60px",
    transform: "rotate(3600deg)",
    marginRight: "3px",
  },

  episodesCardContainer: {
    width: "100%",
    minHeight: "19.750em",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    // backgroundColor: theme.palette.background.paper,
    outline: "none",
  },

  modelBodyText: {
    // fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247",
  },

  startTimeTxt: {
    // fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,

    fontSize: "20px",
    lineHeight: "10px",
    color: "#15AEBF",
  },
  msgBody: {
    minHeight: "200px",
    alignItems: "center",
    display: "flex",
  },
  readMore: {
    fontSize: "12px",
    cursor: "pointer",
    color: "#C4C4C4",
    "&:hover": {
      textDecoration: "underline",
      color: "#84CDF0",
    },
  },
  scroll: {
    height: "100vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0px",
    },
  },
  heartIconStyle: {
    paddingTop: "15px",
    cursor: "pointer",
    marginRight: "15px",
  },
  button: {
    textTransform: "none",
    color: "#09B1D3",
    cursor: "pointer",
    fontSize: "70px",
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%",
    height: 0,
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },

  ratio: {
    position: "relative",
    width: "100%",
    height: 0,

    paddingTop: `56.25%`,
  },
  preLoadBody: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#a9a9a9",
    width: "100%",
  },
  closeIcon: {
    color: "#a9a9a9",
    float: "right",
    cursor: "pointer",
  },
}));
