import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Box } from "@material-ui/core";
import CourseDetails from "../components/instructorDetails/view-InstructorDetail";
import { Helmet } from "react-helmet";

const Blog = (props) => {
  const { data } = props;
  const instructor = props?.pageContext?.instructor;

  const categories = props?.pageContext?.catergories?.data;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const seoData = {
    title: instructor?.name,
    description: instructor?.description,
    image: instructor?.image,
    keyword: instructor?.name,
    url: `/teachers/${instructor.name.replace(/\s/g, "-")}-${instructor.id}`,

  };

  return (
    <Layout seoData={seoData}>
      <Helmet>
        <title>{instructor?.name}</title>
      </Helmet>
      <Box
        className={isMobile ? classes.descriptionMob : classes.description}
        style={{ fontSize: isXS ? "12px" : isMobile && "20px" }}
      >
        <div className="ck-content">
          <CourseDetails
            instructorDetail={instructor}
            categories={categories}
          />
        </div>
      </Box>
    </Layout>
  );
};

export default Blog;

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    color: "#fff",
    margin: "0px 10px 10px 0px",
    //    height: '52px'
  },
  container: {
    // minHeight: "360px",
    // width: "100%",
    // backgroundPosition: "center",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",

    width: "100%",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: 0,
    position: "relative",
    paddingTop: "33.33%",
  },
  innerContainer: {
    // minHeight: "360px",
    // width: "100%",
    background: "rgba(0,0,0,0.2)",

    display: "flex",
    alignItems: "center",

    // background: "rgba(242,135,5,0.46)",
    position: "absolute",
    left: 0,
    bottom: 0,
    top: 0,
    right: 0,
  },
  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "55px",
    lineHeight: "64px",
    color: "#FFFFFF",
    textAlign: "center",
  },

  descHeading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
    textAlign: "center",
    color: "#3D3D3D",
    padding: "14px 35px",
  },
  description: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    // fontWeight: 500,
    fontSize: "20px",
    // lineHeight: "151.69%",
    // letterSpacing: "0.045em",
    padding: "14px 70px",
    textAlign: "justify",
  },
  descriptionMob: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    // fontWeight: 500,
    fontSize: "20px",
    // lineHeight: "151.69%",
    // letterSpacing: "0.045em",
    // padding: "14px 70px",
    textAlign: "justify",
  },
  date: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "151.69%",
    letterSpacing: "0.045em",
    marginRight: "20px",
    marginBottom: "20px",
  },
}));
