import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  useMediaQuery,
  CardContent,
  Typography,
  RadioGroup,
  IconButton,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "gatsby";

import { RELATE_APP_BASE_URL } from "../../utils/constants";

function CourseEpisodesComponent(props) {
  const classes = useStyles();
  const [image, setImage] = useState();
  const [imagePath, setImagePath] = useState("");

  /*   if (imagePath === "") {
    let src = props?.data?.image;
    if (!src.includes(".webp")) {
      var ext = src.split(".");

      var text = ext[0] + "_thumb";

      let NewUrl = text + ".webp";

    setImagePath(src);
  } */
  /*   function preloadImage(src) {
    return new Promise((resolve, reject) => {

      const img = new Image();
      img.onload = function () {
        resolve(img);

      };
      img.onerror = img.onabort = function () {
        reject(src);
      };
      img.src = src;
      console.log(src,"src")
      var ext = src.split('.');
      console.log(ext,"EXTTT")
      var  text = ext[0] + "_thumb";
        ext.splice(1, 1, text);
       let newUrl = ext.join(".")
        console.log(ext, "******")
        console.log(newUrl,"*newUrl*****")
       // let result = src.replace("png", "webp");
     //  setImage(newUrl);
      setImage(newUrl);
    });
  } */

  /*  useEffect(() => {
    preloadImage(props?.data?.image);
  }, []); */

  return (
    <Card className={classes.cardContainer}>
      <CardContent style={{ backgroundColor: "#FFF" }}>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <Typography
              variant="h6"
              component="h6"
              gutterBottom
              className={classes.textStyle}
              style={{ fontSize: "16px", alignItems: "center" }}
            >
              {props?.ind}
            </Typography>
          </Grid>
          {/* <div>
            <Link
              style={{
                textDecoration: "none",
                color: "#FFFF",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
              to={`/courses/${props?.data?.slug}`}
              target="_self"
            > */}
          <Grid item xs={5} sm={3} md={3} lg={3} xl={3}>
            <div
              style={{
                // background: `url(${RELATE_APP_BASE_URL}${props?.data?.image}), #C4C4C4`,
                background: `url(${RELATE_APP_BASE_URL}${props?.data?.image}), #C4C4C4`,

                backgroundSize: "contain",
              }}
              className={classes.imageContainer}
            />
          </Grid>
          <Grid container item xs={6} sm={8} md={8} lg={8} xl={8}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="h6"
                gutterBottom
                className={classes.textStyle}
                style={{ fontSize: "16px" }}
              >
                {props?.data?.name}
              </Typography>
            </Grid>
          </Grid>
          {/* </Link>
          </div> */}
          <Grid
            item
            xs={12}
            style={{ marginLeft: "50px", maxWidth: "100%", overflow: "hidden" }}
          >
            {props?.data?.description && (
              <div
                className={classes.courseDestextStyle}
                style={{
                  fontSize: "14px",
                  width: "100%",
                  overflow: "hidden",
                }}
                dangerouslySetInnerHTML={{
                  __html: unescape(props?.data?.description),
                }}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CourseEpisodesComponent;

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    height: 0,
    paddingTop: "56.25%",
    position: "relative",
    borderRadius: "9px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
  },
  cardContainer: {
    background: "#FFF",
    // marginTop: '10px',
    boxShadow: "none",
    borderTop: "1px solid rgba(102, 102, 102, 0.19)",
  },
  textStyle: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "16px",
    color: "#3D3D3D",
    marginBottom: "10px",
    width: "100%",
  },
  courseDestextStyle: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "16px",
    textAlign: "justify",
    color: "#3D3D3D",
    marginBottom: "10px",
    width: "100%",
  },
}));
